import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "./Contact.css"
import swal from 'sweetalert';
import mssg from "../../assests/mssg.png"

const Contact = () => {
  const [userData, setUserData] = useState({
    fname: "",
    email: "",
    phoneNo: "",
    collegeName: "",
    requestReason: ""
  });
  const [loading, setLoading] = useState(false);

  const postUserData = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { fname, email, phoneNo, collegeName, requestReason } = userData;
  
    // Basic validation for the form
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
  
    if (!fname || !email || !phoneNo || !collegeName) {
      swal({
        title: "Oops!",
        text: "All fields except Message are mandatory!",
        icon: "error",
        button: "Back!",
      });
      return;
    }
  
    if (!emailRegex.test(email)) {
      swal({
        title: "Invalid Email!",
        text: "Please provide a valid email address.",
        icon: "error",
        button: "Back!",
      });
      return;
    }
  
    if (!phoneRegex.test(phoneNo)) {
      swal({
        title: "Invalid Phone Number!",
        text: "Please provide a valid 10-digit mobile number.",
        icon: "error",
        button: "Back!",
      });
      return;
    }
  
    if (requestReason && requestReason.length > 250) {
      swal({
        title: "Message Too Short!",
        text: "The message must be at least 250 characters long.",
        icon: "error",
        button: "Back!",
      });
      return;
    }
  
    setLoading(true);
  
    try {
      const requestBody = JSON.stringify({
        eventID: "1005",
        addInfo: {
          name: fname,
          email: email,
          mobile: phoneNo,
          college_name: collegeName,
          requestReason: requestReason || "None", // If no message, set it as "None"
        },
      });
  
      // Log the request body to the console
      console.log("Request Body:", requestBody);
  
      const response = await fetch("https://proxy-apis-9604e2d1e9ca.herokuapp.com/Reminder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });
  
      const result = await response.json();
  
      if (result.rStatus === 0) {
        setUserData({
          fname: "",
          email: "",
          phoneNo: "",
          collegeName: "",
          requestReason: "",
        });
        swal({
          title: "Success!",
          text: result.rData.rMessage,
          icon: "success",
          button: "Okay!",
        });
      } else {
        swal({
          title: "Oops!",
          text: "There was an issue with your request. Please try again.",
          icon: "error",
          button: "Back!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        title: "Oops!",
        text: "There was an issue with your request. Please try again.",
        icon: "error",
        button: "Back!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="Contact">
      <Container>
        <div className='contact_heading_div'>
          <h1 className='contact_us'>Contact Us</h1>
          <h1 className='contact_heading'>Get In <span className='span'> Touch</span></h1>
          <p>Stay Connected With Us</p>
        </div>

        <div className="contact_header">
          <div className='flex2'>
            <img width={100} height={100} className='mssg_img' src={mssg} alt="Message Icon" />
            <a href="mailto:info@proxyy.in" className='a'>
              <p className='a'>info@proxyy.in</p>
            </a>
          </div>
          <div className='flex2 flex1'>
            <img width={100} height={100} className='mssg_img' src={mssg} alt="Message Icon" />
            <a href="tel:+918171330405" className='a'>
              <p className="a">+91-817133-0405</p>
            </a>
          </div>
        </div>
      </Container>
      <Container className="form_container">
  <p style={{ textAlign: "center", marginBottom: "3.5rem", fontSize: 20, fontWeight: "bold" }}>
    We will contact you after receiving your request within 24 hours
  </p>
  <form onSubmit={submitData}>
    <div className="form_wrapper">
      <Row>
        <Col lg={6} md={12} xs={12}>
          <div>
            <label className="input_labels">Name</label>
            <input
              type="text"
              className="input_fields"
              placeholder="Name"
              name="fname"
              value={userData.fname}
              onChange={postUserData}
            />
          </div>
        </Col>
        <Col lg={6} md={12} xs={12}>
          <div>
            <label className="input_labels">Email</label>
            <input
              className="input_fields"
              name="email"
              type="email"
              placeholder="Email"
              value={userData.email}
              onChange={postUserData}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} xs={12}>
          <div>
            <label className="input_labels">Mobile Number</label>
            <input
              className="input_fields"
              name="phoneNo"
              type="text"
              placeholder="Mobile Number"
              value={userData.phoneNo}
              onKeyPress={(e) => {
                const onlyNumeric = /[0-9]/g;
                const isNumeric = onlyNumeric.test(e.key);
                const isMaxLength = e.target.value.length < 10;
                if (!isNumeric || !isMaxLength) {
                  e.preventDefault();
                }
              }}
              onChange={postUserData}
            />
          </div>
        </Col>
        <Col lg={6} md={12} xs={12}>
          <div>
            <label className="input_labels">College Name</label>
            <input
              className="input_fields"
              name="collegeName"
              type="text"
              placeholder="College Name"
              value={userData.collegeName}
              onChange={postUserData}
            />
          </div>
        </Col>
        <Col lg={12} md={12} xs={12}>
          <div>
            <label className="input_labels">Message</label>
            <input
              className="input_fields"
              name="requestReason"
              type="text"
              placeholder="Message (optional)"
              value={userData.requestReason}
              onChange={postUserData}
            />
          </div>
        </Col>
      </Row>

      <div className="submit_btndiv">
        <button className="submit_btn" type="submit" disabled={loading}>
          {loading ? "Sending..." : "Send Your Request"}
        </button>
      </div>
    </div>
  </form>
</Container>
    </section>
  )
}

export default Contact
