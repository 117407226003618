import React from 'react'
import "./Icons.css"
import { faSquareInstagram, faSquareWhatsapp, faLinkedin, } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebookF } from "react-icons/fa"
import { FiInstagram } from "react-icons/fi"
import { BsWhatsapp } from "react-icons/bs"
import { FaLinkedinIn } from "react-icons/fa"

const Icons = () => {
  return (

    <><div className='social_container'>

      <a href="https://www.facebook.com/profile.php?id=100084463219381&mibextid=LQQJ4d">
        <img className='facebook'
          src={require('../assests/facebook.png')} />
      </a>

      <a href="https://instagram.com/timely_proxyy?igshid=MWI4MTIyMDE=">
        <img className='insta'
          src={require('../assests/instagram.png')} />
      </a>

      <a href="https://wa.me/message/YVV3N7ZVZMAJH1" >
        <img className='whatsapp'
          src={require('../assests/whatsapp.png')} />
      </a>
      <a href="https://www.linkedin.com/company/timelyproxyy/">
        <img className='linkedin'
          src={require('../assests/linkedin.png')} />
      </a>



    </div>
    </>
  )
}

export default Icons
