const data = [
    {
        id: "1",
        question: "How to download the app?",
        answer: "Just request a free demo session to get the mobile App"
    },
    {
        id: "2",
        question: "How to get the attendance marked?",
        answer: " Get your attendance marked, just you need to scan the QR which is generated by the teacher ."
    },
    {
        id: "3",
        question: "Can students access their attendance records in the college attendance portal?",
        answer: "Yes, students can usually access their attendance records in the college attendance portal. This allows them to monitor their own attendance and take steps to improve their engagement in class if necessary."
    },
    {
        id: "4",
        question: "Is student data secure in a college attendance portal?",
        answer: "Yes, student data is typically encrypted and stored securely in a college attendance portal. The portal is designed to comply with relevant privacy laws and regulations to protect student information."
    },
    {
        id: "3",
        question: "Why is a college attendance portal important?",
        answer: "A college attendance portal is important because it helps faculty and administrators monitor student attendance and engagement in class. This information can be used to identify at-risk students and provide targeted support to improve their academic performance."
    },

]
export default data;