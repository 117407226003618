import { Container, Navbar, Nav, Form, Button } from 'react-bootstrap';
import "./Navbar.css"
import navlogo from "../../assests/logo-no-background.png"
import headerImg from "../../assests/headerImg.jpg"
import Icons from "../../Icons/Icons"
import { useState } from 'react';


function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const handleNavClick = () => {
    setExpanded(false); // Collapse the navbar on click
  };
  return (
    <>
      <Container fluid style={{ backgroundImage: `url(${headerImg})`, height: "70px", width: "100%", marginBottom: "0px", position: "fixed" }}>
        <div className="header_text_div">
          <p className="header_text">
            Get 20% Discount Get for New Query
          </p>
          <a href="#Contact">
            <p className="header_text" style={{ marginLeft: "8px", textDecoration: "underline", fontWeight: "400" }}>
              Request Demo
            </p>
          </a>
        </div>
      </Container>

      <Navbar expanded={expanded} onToggle={setExpanded} fixed="top" className="Navbar" expand="lg">
        <Container fluid>
          <Navbar.Brand className="NavbarBrand" href="#">
            <img className="logo_img img-fluid" src={navlogo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="nav_links" style={{ height: 'auto', width: "auto" }} navbarScroll>
              <Nav.Link className='links' href="#Home" onClick={handleNavClick}>Home</Nav.Link>
              <Nav.Link className='links' href="#About" onClick={handleNavClick}>About</Nav.Link>
              <Nav.Link className='links' href="#FAQ" onClick={handleNavClick}>FAQ</Nav.Link>
              <Nav.Link className='links' href="#Contact" onClick={handleNavClick}>Contact</Nav.Link>
            </Nav>
            <Form className="d-flex search_nav">
              <Icons />
              {/* <button className='nav_btn'>Join tech hub </button> */}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;  